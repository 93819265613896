import React from "react";

import { ListFirsSigmetsData } from "@weatheredstrip/sdk";

interface SIGMETsProps {
  data: ListFirsSigmetsData["sigmets"];
}

const SIGMETs = ({ data }: SIGMETsProps) => (
  <div>
    {data.map((sigmet) => (
      <div key={sigmet.id} className="notif-text">
        <div>{sigmet.rawSigmet}</div>
      </div>
    ))}
  </div>
);
export default SIGMETs;

import React from "react";
import {
  Airport,
  CanadianAirport,
  GFA,
  GFAType,
  Station,
  isCanadianAirport,
} from "@weatheredstrip/shared";

function renderAllGFA(data: (Station | Airport | CanadianAirport)[]) {
  const weatherGFAs: GFA[] = [];
  const icingGFAs: GFA[] = [];

  const recordedGFAs: string[] = [];

  data.forEach((station) => {
    if (isCanadianAirport(station)) {
      station.gfa?.forEach((gfa) => {
        if (!recordedGFAs.some((recordedURL) => gfa.imageURL === recordedURL)) {
          recordedGFAs.push(gfa.imageURL);
          if (gfa.type === GFAType.Cloud) {
            weatherGFAs.push(gfa);
          }

          if (gfa.type === GFAType.Icing) {
            icingGFAs.push(gfa);
          }
        }
      });
    }
  });

  const orderedGFAs: React.ReactElement[] = [];

  weatherGFAs.forEach((weatherGFA, index) => {
    orderedGFAs.push(
      <img
        key={weatherGFA.imageURL}
        src={weatherGFA.imageURL}
        alt={weatherGFA.imageURL}
      />
    );
    orderedGFAs.push(
      <img
        key={icingGFAs[index].imageURL}
        src={icingGFAs[index].imageURL}
        alt={icingGFAs[index].imageURL}
      />
    );
  });

  const groupedGFAs: Array<Array<React.ReactElement>> = [];

  for (let iter = 0; iter < orderedGFAs.length; iter += 6) {
    groupedGFAs.push(orderedGFAs.slice(iter, iter + 6));
  }

  return (
    <div className="print-station">
      <div className="station-name">GFAs</div>
      {groupedGFAs.map((gfaGroup, groupIndex) => (
        <React.Fragment key={groupIndex}>
          <div className="print-gfa-group">
            {gfaGroup.map((gfa, index) => gfa)}
          </div>
          <div className="page-break" />
        </React.Fragment>
      ))}
    </div>
  );
}

export default renderAllGFA;

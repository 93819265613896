import {
  AirportDocument,
  UserTier,
  ValidIATACode,
  ValidICAOCode,
  isIATACode,
  isICAOCode,
} from "@weatheredstrip/shared";
import { useAppSelector, useAuthUser } from "../hooks/index";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { selectStations } from "../pages/Request/querySlice";
import { FirebaseContext } from "../firebase";
import {
  CollectionReference,
  Query,
  QuerySnapshot,
  collection,
  getDocs,
  onSnapshot,
  or,
  query,
  where,
} from "firebase/firestore";

export interface AirportDocumentWithPreferedCode extends AirportDocument {
  prefered_code: "icao_code" | "iata_code";
}

interface IAirportsContext {
  airports: AirportDocumentWithPreferedCode[];
  selected: AirportDocumentWithPreferedCode | null;
  setSelected: React.Dispatch<
    React.SetStateAction<AirportDocumentWithPreferedCode | null>
  >;
}

const AirportsContext = createContext({} as IAirportsContext);

export const addPreference = (
  station: AirportDocument,
  preference: "iata_code" | "icao_code"
): AirportDocumentWithPreferedCode => {
  return {
    ...station,
    prefered_code: preference,
  };
};

const buildOrderedListWithPreference = (
  stations: (ValidICAOCode | ValidIATACode)[],
  snapshot: QuerySnapshot<AirportDocument, AirportDocument>
): AirportDocumentWithPreferedCode[] => {
  const stationRecords: AirportDocument[] = [];
  snapshot.forEach((record) => {
    stationRecords.push(record.data());
  });

  const orderedStationRecords: AirportDocumentWithPreferedCode[] = Array(
    stationRecords.length
  );

  for (const stationRecord of stationRecords) {
    const icao_index = stations.indexOf(stationRecord.icao_code);
    const iata_index = stationRecord.iata_code
      ? stations.indexOf(stationRecord.iata_code)
      : -1;

    if (icao_index !== -1) {
      orderedStationRecords[icao_index] = addPreference(
        stationRecord,
        "icao_code"
      );
    } else if (iata_index !== -1) {
      orderedStationRecords[iata_index] = addPreference(
        stationRecord,
        "iata_code"
      );
    }
  }

  return orderedStationRecords;
};

export const StationsProvider = ({ children }: { children: ReactNode }) => {
  const stations = useAppSelector(selectStations);
  const firebase = useContext(FirebaseContext);
  const auth = useAuthUser();
  const [airports, setAirports] = useState<AirportDocumentWithPreferedCode[]>(
    []
  );

  const [selected, setSelected] =
    useState<AirportDocumentWithPreferedCode | null>(null);

  const profileTier = auth?.profile?.tier ?? UserTier.Basic;

  useEffect(() => {
    if (
      (airports.length > 0 && !selected) ||
      !airports.some((airport) => airport.icao_code === selected?.icao_code)
    ) {
      setSelected(airports[0]);
    }
  }, [selected, airports]);

  useEffect(() => {
    if (!firebase.firestore || !stations.length) return;

    const iata_codes: ValidIATACode[] = [];
    const icao_codes: ValidICAOCode[] = [];

    stations.forEach((station) => {
      if (isIATACode(station)) {
        iata_codes.push(station);
      }
      if (isICAOCode(station)) {
        icao_codes.push(station);
      }
    });

    const airportsCollectionRef = collection(
      firebase.firestore,
      "airports"
    ) as CollectionReference<AirportDocument, AirportDocument>;

    if (!iata_codes.length && !icao_codes.length) {
      setAirports([]);
      return;
    }

    let stations_query: Query<AirportDocument, AirportDocument>;

    if (iata_codes.length && icao_codes.length) {
      stations_query = query(
        airportsCollectionRef,
        or(
          where("iata_code", "in", iata_codes),
          where("icao_code", "in", icao_codes)
        )
      );
    } else {
      if (!iata_codes.length) {
        stations_query = query(
          airportsCollectionRef,
          where("icao_code", "in", icao_codes)
        );
      } else {
        stations_query = query(
          airportsCollectionRef,
          where("iata_code", "in", iata_codes)
        );
      }
    }

    if (profileTier >= UserTier.Paid) {
      const unsubscribe = onSnapshot(stations_query, (snapshot) => {
        const orderedStationRecords = buildOrderedListWithPreference(
          stations,
          snapshot
        );
        setAirports(orderedStationRecords);
      });

      return () => {
        unsubscribe();
      };
    } else {
      getDocs(stations_query).then((snapshot) => {
        const orderedStationRecords = buildOrderedListWithPreference(
          stations,
          snapshot
        );
        setAirports(orderedStationRecords);
      });

      return () => {};
    }
  }, [firebase.firestore, profileTier, stations]);

  return (
    <AirportsContext.Provider value={{ airports, selected, setSelected }}>
      {children}
    </AirportsContext.Provider>
  );
};

export const TestStationsProvider = ({
  children,
  fixtureAirports = [],
  fixtureSelected = null,
}: {
  children: ReactNode;
  fixtureAirports?: AirportDocumentWithPreferedCode[];
  fixtureSelected?: AirportDocumentWithPreferedCode | null;
}) => {
  const [selected, setSelected] =
    useState<AirportDocumentWithPreferedCode | null>(fixtureSelected);

  useEffect(() => {
    if (fixtureAirports.length > 0 && !selected) {
      setSelected(fixtureAirports[0]);
    }
  }, [selected, fixtureAirports]);

  return (
    <AirportsContext.Provider
      value={{ airports: fixtureAirports, selected, setSelected }}
    >
      {children}
    </AirportsContext.Provider>
  );
};

export const useAirportsContext = (): IAirportsContext => {
  const context = useContext(AirportsContext);

  if (!Object.keys(context).length) {
    // if context is empty, we are not within a StationsProvider
    throw new Error(
      "useAirportsContext must be used within a StationsProvider"
    );
  }

  return context;
};

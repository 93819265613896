import React, { forwardRef } from "react";
import Metars from "../../metars";
import Tafs from "../../tafs";
import {
  Airport,
  Station,
  STATION_TYPE_AIRPORT,
  ValidICAOCode,
} from "@weatheredstrip/shared";
import renderNotam from "./renderers/notam";
import renderGpsNotams from "./renderers/gpsNotam";
import renderFIRNotams from "./renderers/firNotam";
import renderAllGFA from "./renderers/gfa";
import { useAppSelector } from "../../../../hooks/index";
import { selectSigmets } from "../../sigmetSlice";
import SIGMETs from "./renderers/sigmets";

interface PropsType {
  data: Station[];
}

function isAirport(station: Station): station is Airport {
  return station.station_type === STATION_TYPE_AIRPORT;
}

const PrintView = forwardRef(
  (
    { data }: React.PropsWithRef<PropsType>,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const sigmets = useAppSelector(selectSigmets);

    let gfas: React.ReactElement;
    let contentArray: (React.JSX.Element | null)[];
    // since KGPS is always searched, length always >= 1
    if (data && data.length > 1) {
      gfas = renderAllGFA(data);

      contentArray = data.map((stationData) => {
        if (isAirport(stationData)) {
          const aerodromeNotams = renderNotam(
            stationData.notam_EN,
            "aerodrome"
          ) || (
            <div className="notif-text">
              No Aerodrome NOTAM found for this station
            </div>
          );
          const areaNotams = renderNotam(stationData.notam_EN, "area") || (
            <div className="notif-text">
              No Area NOTAM found for this station
            </div>
          );
          const FIRNotams =
            stationData.iso_country === "CA"
              ? renderNotam(stationData.notam_EN, "FIR") || (
                  <div className="notif-text">
                    No NOTAM for {stationData.FIR}.
                  </div>
                )
              : renderFIRNotams(
                  data.filter(
                    (firStation) => firStation.codes[0] === stationData.FIR
                  )[0].notam_EN
                ) || (
                  <div className="notif-text">
                    No NOTAM for {stationData.FIR}.
                  </div>
                );
          const nationalNotams = renderNotam(
            stationData.notam_EN,
            "national"
          ) || (
            <div className="notif-text">No National NOTAM at this time.</div>
          );
          return (
            <div ref={ref} key={stationData.codes[0]} className="print-station">
              <div className="station-name">
                {`${stationData.codes[0]} - ${stationData.name}`}
              </div>
              {sigmets.length > 0 && (
                <>
                  <div className="subtitle">SIGMET</div>
                  <SIGMETs data={sigmets} />
                </>
              )}
              <div className="subtitle">METAR</div>
              <Metars data={stationData.metar} />
              <div className="subtitle">TAF</div>
              <Tafs data={stationData.taf} />
              <div className="subtitle">NOTAM</div>
              <div className="notam-type">Aerodrome</div>
              <div>{aerodromeNotams}</div>
              <div className="notam-type">Area</div>
              <div>{areaNotams}</div>
              <div className="notam-type">FIR</div>
              <div>{FIRNotams}</div>
              <div className="notam-type">National</div>
              <div>{nationalNotams}</div>
            </div>
          );
        } else if (
          stationData.type === "other" &&
          stationData.codes.indexOf("KGPS" as ValidICAOCode) >= 0
        ) {
          const gpsNotams = renderGpsNotams(stationData.notam_EN) || (
            <div className="notif-text">No GPS NOTAM at this time.</div>
          );
          return (
            <div ref={ref} key={stationData.codes[0]} className="print-station">
              <div className="station-name">{stationData.codes[0]}</div>
              <div className="subtitle">NOTAM</div>
              <div>{gpsNotams}</div>
            </div>
          );
        } else {
          return null;
        }
      });
    } else {
      return (
        <div ref={ref}>
          No content specified. Ensure a search was done before printing.
        </div>
      );
    }

    return (
      <div ref={ref}>
        {gfas}
        {contentArray}
      </div>
    );
  }
);

export default PrintView;

import { queryRef, executeQuery, mutationRef, executeMutation, validateArgs } from 'firebase/data-connect';

export const connectorConfig = {
  connector: 'js-sdk',
  service: 'aviation-information',
  location: 'northamerica-northeast1'
};

export function seedSigmetRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  dcInstance._useGeneratedSdk();
  return mutationRef(dcInstance, 'SeedSigmet');
}

export function seedSigmet(dc) {
  return executeMutation(seedSigmetRef(dc));
}

export function listSigmetsRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'ListSigmets');
}

export function listSigmets(dc) {
  return executeQuery(listSigmetsRef(dc));
}

export function listFirSigmetsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'ListFIRSigmets', inputVars);
}

export function listFirSigmets(dcOrVars, vars) {
  return executeQuery(listFirSigmetsRef(dcOrVars, vars));
}

export function listFirsSigmetsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'ListFirsSigmets', inputVars);
}

export function listFirsSigmets(dcOrVars, vars) {
  return executeQuery(listFirsSigmetsRef(dcOrVars, vars));
}

export function listFirsRef(dc) {
  const { dc: dcInstance} = validateArgs(connectorConfig, dc, undefined);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'ListFirs');
}

export function listFirs(dc) {
  return executeQuery(listFirsRef(dc));
}

export function listExistingSigmetIdsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'ListExistingSigmetIds', inputVars);
}

export function listExistingSigmetIds(dcOrVars, vars) {
  return executeQuery(listExistingSigmetIdsRef(dcOrVars, vars));
}


import { ListFirsSigmetsData, listFirsSigmets } from "@weatheredstrip/sdk";
import { ThunkStatus } from "../../store/constants";
import { createAppSlice } from "../../store/createAppSlice";
import { ValidIATACode, ValidICAOCode } from "@weatheredstrip/shared";
import { AirportDocumentWithPreferedCode } from "../../context/airports";

export interface SigmetsSliceState {
  status: ThunkStatus;
  data: ListFirsSigmetsData["sigmets"];
  stations: string[];
  timestamp: string | null;
}

const initialState: SigmetsSliceState = {
  status: ThunkStatus.Idle,
  data: [],
  stations: [],
  timestamp: null,
};

// If you are not using async thunks you can use the standalone `createSlice`.
export const sigmetsSlice = createAppSlice({
  name: "sigmets",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: (create) => ({
    clear: create.reducer(() => {
      return initialState;
    }),
    // The function below is called a thunk and allows us to perform async logic. It
    // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
    // will call the thunk with the `dispatch` function as the first argument. Async
    // code can then be executed and other actions can be dispatched. Thunks are
    // typically used to make async requests.
    querySigmets: create.asyncThunk(
      async (stations: AirportDocumentWithPreferedCode[]) => {
        const firs = stations
          .map((airport) => airport.FIR)
          .reduce(
            (acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]),
            [] as string[]
          );

        const sigmets = await listFirsSigmets({ firIds: firs });

        // The value we return becomes the `fulfilled` action payload
        return sigmets.data.sigmets;
      },
      {
        pending: (state) => {
          state.status = ThunkStatus.Pending;
        },
        fulfilled: (state, action) => {
          state.data = action.payload;
          state.stations = action.meta.arg.map(
            (station) => station.prefered_code
          );
          state.timestamp = new Date().toUTCString();
          state.status = ThunkStatus.Idle;
        },
        rejected: (state) => {
          state.status = ThunkStatus.Failed;
        },
      }
    ),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    selectSigmets: (query) => query.data,
    selectSigmetsStatus: (sigmets) => sigmets.status,
    selectSigmetsStations: (sigmets) =>
      sigmets.stations as (ValidIATACode | ValidICAOCode)[],
    selectSigmetsTimestamp: (sigmets) => sigmets.timestamp,
  },
});

// Action creators are generated for each case reducer function.
export const { querySigmets, clear } = sigmetsSlice.actions;

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
  selectSigmets,
  selectSigmetsStatus,
  selectSigmetsTimestamp,
  selectSigmetsStations,
} = sigmetsSlice.selectors;

import React, { useContext, useRef } from "react";
import ReactToPrint from "react-to-print";

import PrintView from "./components/printview";
import { Station } from "@weatheredstrip/shared";
import { logEvent } from "firebase/analytics";
import { FirebaseContext } from "../../firebase";
import {
  AirportDocumentWithPreferedCode,
  useAirportsContext,
} from "../../context/airports";

export function RenderedStations({
  airports,
  selected,
  setSelected,
}: {
  airports: AirportDocumentWithPreferedCode[];
  selected: AirportDocumentWithPreferedCode | null;
  setSelected: (airport: AirportDocumentWithPreferedCode) => void;
}) {
  return airports.map((airport) => {
    const divClass =
      airport.icao_code === selected?.icao_code
        ? "station station-selected"
        : "station";
    return (
      <button
        className={divClass}
        key={airport.icao_code}
        onClick={() => {
          setSelected(airport);
        }}
      >
        {airport[airport.prefered_code]}
      </button>
    );
  });
}

interface PropsType {
  data: Station[];
}

function StationNav({ data }: PropsType) {
  const firebase = useContext(FirebaseContext);

  const { selected, setSelected, airports } = useAirportsContext();
  const componentRef = useRef<HTMLDivElement>(null!);

  if (data) {
    return (
      <div id="navbar">
        <div className="navbar-content">
          <RenderedStations
            airports={airports}
            selected={selected}
            setSelected={setSelected}
          />
          <ReactToPrint
            trigger={() => (
              <button
                onClick={() => logEvent(firebase.analytics, "Print")}
                className="button primary print-button"
                key="print"
              >
                Print
              </button>
            )}
            content={() => componentRef.current}
            bodyClass="print-body"
            pageStyle=""
          />
        </div>
        <div style={{ display: "none" }}>
          <PrintView data={data} ref={componentRef} />
        </div>
      </div>
    );
  } else {
    return <div id="navbar" />;
  }
}

export default StationNav;

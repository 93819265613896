import React from "react";
import { NOTAM } from "@weatheredstrip/shared";

// TODO: Be more specific with the types
function renderGpsNotams(notams: NOTAM[] | null) {
  if (notams !== null) {
    return notams.map((notam) => (
      <div className="notif-text" key={notam.title}>
        <div>
          <strong>{notam.title}</strong>
        </div>
        <div>{notam.notam}</div>
      </div>
    ));
  } else {
    return null;
  }
}

export default renderGpsNotams;

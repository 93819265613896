import React from "react";
import {
  NOTAM,
  CanadianNOTAM,
  NOTAMType,
  isCanadianNOTAM,
} from "@weatheredstrip/shared";
import {
  NotamSchedule,
  formatEndValidity,
  formatStartValidity,
} from "../../notam";

export function renderNotamValidity(notam: NOTAM) {
  return `${formatStartValidity(notam.startValidity)} - ${formatEndValidity(
    notam.endValidity
  )} ${notam.isEndEstimated ? "EST." : ""}`;
}

function renderNotam(
  notams: NOTAM[] | CanadianNOTAM[] | null,
  type: NOTAMType
) {
  if (notams) {
    const typed = notams.filter((notam) => notam.type === type);
    if (typed.length === 0) {
      return null;
    } else {
      return typed.map((notam, index) => {
        return (
          <div className="notif-text" key={index}>
            {notam && notam.link ? (
              <div>
                <a href={notam.link} rel="noopener noreferrer" target="_blank">
                  <strong>{notam.title} - </strong>
                  <i className="fas fa-external-link-alt"></i>
                </a>
              </div>
            ) : (
              <div>
                <strong>{notam.title}</strong>
                <span>{renderNotamValidity(notam)}</span>
              </div>
            )}
            {isCanadianNOTAM(notam) &&
              notam.schedule?.length > 0 &&
              NotamSchedule(notam)}
            <div>{notam.notam}</div>
          </div>
        );
      });
    }
  }
}

export default renderNotam;

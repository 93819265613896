import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Content from "./content";
import Footer from "./footer";
import Header from "./header";

import "./Request.css";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  clear,
  query,
  selectData,
  selectStations,
  selectStatus,
  selectTimestamp,
} from "./querySlice";
import { extractStationsFrom, filterStationCodes } from "./utils";
import { StationsProvider } from "../../context/airports";

function Request() {
  const dispatch = useAppDispatch();
  const stations = useAppSelector(selectStations);
  const data = useAppSelector(selectData);
  const status = useAppSelector(selectStatus);
  const rawTimestamp = useAppSelector(selectTimestamp);
  const { search } = useLocation();

  useEffect(() => {
    const stations = extractStationsFrom(search);

    const codes = filterStationCodes(stations);

    if (codes.length > 0) {
      dispatch(query(codes));
    }
    return () => {
      dispatch(clear());
    };
  }, [search, dispatch]);

  return (
    <StationsProvider>
      <Header />
      <Content
        data={data}
        status={status}
        timestamp={rawTimestamp ? new Date(rawTimestamp) : null}
        search={stations}
      />
      <Footer />
    </StationsProvider>
  );
}

export default Request;

export { default as Content } from "./content";
export { default as Footer } from "./footer";
export { default as GFA } from "./gfa";
export { default as Header } from "./header";
export { default as Metars } from "./metars";
export { default as Notams } from "./notams";
export { default as Rvr } from "./rvr";
export { default as StationNav } from "./stationNav";
export { default as Tafs } from "./tafs";
export { default as Timestamp } from "./timestamp";

import React, { useContext, useState } from "react";
import {
  Alert,
  Dialog,
  Button,
  Pane,
  TextInput,
  Menu,
  LogInIcon,
} from "evergreen-ui";

import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { FirebaseContext } from "../../firebase";

const SignInMenu = () => {
  const [showSignIn, setShown] = useState(false);
  return (
    <>
      <SignInDialog isShown={showSignIn} onClose={() => setShown(false)} />
      <Menu.Item
        icon={LogInIcon}
        onSelect={() => {
          setShown(true);
        }}
      >
        Sign In
      </Menu.Item>
    </>
  );
};

type PropsType = {
  isShown: boolean;
  onClose: () => void;
};

const SignInDialog = ({ isShown, onClose }: PropsType) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const firebase = useContext(FirebaseContext);

  const isInvalid = password === "" || email === "";

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await firebase.doSignInWithEmailAndPassword(email, password);
      onClose();
    } catch (error) {
      if (error.code === "auth/invalid-login-credentials") {
        setError(Error("Invalid Login Credentials"));
      } else {
        setError(error);
      }
    }
    setIsLoading(false);
  };

  const signInFooter = ({ close }: { close: () => void }) => {
    return (
      <Button
        appearance="primary"
        disabled={isInvalid || isLoading}
        type="submit"
        form="signInForm"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          onSubmit(event);
        }}
        isLoading={isLoading}
      >
        {isLoading ? "Loading..." : "Sign In"}
      </Button>
    );
  };

  return (
    <Dialog
      title="Sign In"
      isShown={isShown}
      onCloseComplete={() => {
        onClose();
        setEmail("");
        setPassword("");
        setError(undefined);
      }}
      footer={signInFooter}
    >
      <form onSubmit={onSubmit} id="signInForm">
        <Pane display="flex" flexDirection="column" alignItems="center">
          {error && (
            <Alert intent="danger" title={error.message} marginBottom={10} />
          )}
          <TextInput
            name="email"
            value={email}
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              setEmail(event.currentTarget.value)
            }
            type="text"
            placeholder="Email Address"
            marginBottom={10}
          />
          <TextInput
            name="password"
            value={password}
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              setPassword(event.currentTarget.value)
            }
            type="password"
            placeholder="Password"
            marginBottom={10}
          />
          <PasswordForgetLink />
          <SignUpLink />
        </Pane>
      </form>
    </Dialog>
  );
};

export default SignInMenu;
export { SignInDialog };

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import LinkButton from "./linkbutton";
import ROUTES from "../../constants/routes";
import { Search } from "history";
import { extractStationsFrom, filterStationCodes } from "./utils";
import { useAppDispatch } from "../../hooks";
import { query } from "./querySlice";

const getStationsFrom = (search: Search) => {
  const stations = extractStationsFrom(search);

  return stations.join(" ");
};

const SearchBox = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const initialValue = getStationsFrom(location.search);

  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  //  Event handler for change in input.
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    setValue(event.currentTarget.value);
  };

  //  Event handler for form submit
  const handleSearchSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoading(true);

    const searchBoxValue = value.toUpperCase().split(" ");

    const filteredCodes = filterStationCodes(searchBoxValue);

    await dispatch(query(filteredCodes));

    setLoading(false);
  };

  return (
    <form className="searchbox" onSubmit={handleSearchSubmit}>
      <input
        className="searchbox-input"
        type="text"
        aria-label="search-input"
        placeholder="ICAO or IATA codes..."
        value={value}
        onChange={handleChange}
      />
      <LinkButton
        className="searchbox-button"
        aria-label="search-button"
        to={{
          pathname: ROUTES.REQUEST,
          search: `?stations=${value.toUpperCase()}`,
        }}
        type="submit"
        disabled={value === ""}
      >
        {loading ? (
          <FontAwesomeIcon className="spinning" icon={faSyncAlt} />
        ) : (
          <FontAwesomeIcon icon={faSearch} />
        )}
      </LinkButton>
    </form>
  );
};

export default SearchBox;

import { NOTAM } from "@weatheredstrip/shared";
import React from "react";

// TODO: Be more specific with the types
function renderFIRNotams(notams: NOTAM[] | null) {
  if (notams !== null) {
    return notams.map((notam) => {
      return (
        <div className="notif-text" key={notam.title}>
          {notam && notam.link ? (
            <div>
              <a href={notam.link} rel="noopener noreferrer" target="_blank">
                <strong>{notam.title} - </strong>
                <i className="fas fa-external-link-alt"></i>
              </a>
            </div>
          ) : (
            <div>
              <strong>{notam.title}</strong>
            </div>
          )}
          <div>{notam.notam}</div>
        </div>
      );
    });
  }
}

export default renderFIRNotams;
